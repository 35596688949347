import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouteConstants } from './shared/constants/route-constants';
import { UnauthorizedAccessComponent } from './shared/common-component/unauthorized-access/unauthorized-access.component';


const appRoutes: Routes = [
  {
    path: RouteConstants.DEFAULT,
    loadChildren: () => import('./template/template.module').then(m => m.TemplateModule)
  },
 {path: 'unauthorized', component: UnauthorizedAccessComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
