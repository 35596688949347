import { GlobalDataService } from './shared/services/global-data.service';
import { Component } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title;
  urlParams;
  destroyed$ = new Subject<any>();
  hideFullLoader = true;
  constructor(private globalDataService: GlobalDataService) {
    globalDataService.hideLoader.pipe(takeUntil(this.destroyed$)).subscribe(hide => {
      this.hideFullLoader = hide;
    });
  }

}
