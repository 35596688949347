import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class GlobalDataService {
  constructor() {}

  private _hideLoader = new Subject<any>();
  hideLoader = this._hideLoader.asObservable();
  hideFullLoader(hide = true) {
    this._hideLoader.next(hide);
  }
}
