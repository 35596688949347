import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AppConfig {

    constructor(private http: HttpClient) {
    }
    public load() {
        return new Promise((resolve, reject) => {
            this.http.get('assets/resources/app_config.json').pipe(map(res => res), catchError((error: any): any => {
             // Configuration file "app-config.json" could not be read
                resolve(true);
                // tslint:disable-next-line: deprecation
                return Observable.throw(error.json().error || 'Server error');
            })).subscribe((envResponse) => {
                window.localStorage.removeItem('APP_CONFIG');
                window.localStorage.setItem('APP_CONFIG', JSON.stringify(envResponse));
                resolve(true);
            });

        });
    }
}
